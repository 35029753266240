import React from 'react';

class FirstMessage extends React.Component {

    render() {
        return (
            <div className="box-main w-full rounded shadow-xl max-w-6xl h-30 my-5 mx-auto p-5">
                <span>
                    <span className='text-2xl font-bold'>
                        À votre service depuis plus de 20 ans ...
                    </span>
                    <br/><br/>
                    Et ce n'est pas terminé !<br/>
                    Grâce à notre expérience accumulée, nous pouvons vous proposer nos produits et services au meilleur rapport Qualité-prix.<br/>
                    <br/>

                    Nous utilisons des pièces d'origine et en stock, nous permettant de vous proposer des réparations en 1 heure !
                    <br/>
                    Le tout garantie !
                    <br/>
                </span>
                <div className={'flex flex-col md:flex-row items-center justify-center'}>
                    <a href={'/contact'}><button className={'button_secondary raise'}>Nous contacter</button></a>
                    <a href={'/centres'}><button className={'button_secondary raise'}>Se rendre au centre le plus proche</button></a>
                    <a href={'/contact'}><button className={'button_secondary raise'}>Devis gratuit</button></a>
                </div>
            </div>
        )
    }
}

export default FirstMessage;