import React from 'react'
import {GoogleMap, useJsApiLoader, Marker, InfoWindow} from '@react-google-maps/api';
import {myMarkers} from "./stores";

const libs = ['places']
const containerStyle = {
    width: '100vw',
    height: '40vh'
};
const options = {}

function MyMap(callback, deps) {
    const {isLoaded, loadError} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyBc6zcCDq0zE2Xf3UDtdJ9BygGeco1IL5s',
        language: 'FR',
        libraries: libs
    })

    const [zoom, setZoom] = React.useState(10)
    const [isVisibleLabels, setVisibleLabels] = React.useState(false)
    const [center, setCenter] = React.useState({lat: 49.232956343927775, lng: 6.679815994510183})
    const [markers, setMarkers] = React.useState([]);
    const [selected, setSelected] = React.useState(null);

    const onLoadMap = React.useCallback(() => {
        myMarkers.map((item) => setMarkers((current) => [...current, item]))
    }, [])
    if (loadError) return 'Erreur lors du chargement de maps'
    if (!isLoaded) return 'Chargement de Maps'

    function handleZoomChanged() {
        setVisibleLabels(this.getZoom() > 14)
    }

    return (
        <>
            <GoogleMap
                id={'map'}
                mapContainerStyle={containerStyle}
                center={center}
                zoom={zoom}
                options={options}
                onLoad={onLoadMap}
                onZoomChanged={handleZoomChanged}
            >
                {
                    markers.map((marker) => <Marker key={marker.id} position={{lat: marker.lat, lng: marker.lng}}
                                                    onClick={() => setSelected(marker)}
                                                    icon={{
                                                        url: '/static/location.png',
                                                        scaledSize: new window.google.maps.Size(30, 30),
                                                        origin: new window.google.maps.Point(0, 0),
                                                        anchor: new window.google.maps.Point(15, 15),
                                                    }}
                                                    label={isVisibleLabels ? marker.label : null}
                    />)
                }

                {(selected ? (
                    <InfoWindow position={{lat: selected.lat, lng: selected.lng}} onCloseClick={() => {
                        setSelected(null)
                    }}>
                        <div>
                            <h2>{selected.id}</h2>
                            <p>
                                {selected.address.street}<br/>
                                {selected.address.city} {selected.address.CP}<br/>
                                {selected.address.phone}<br/>
                            </p>
                        </div>
                    </InfoWindow>) : null)
                }
                <></>
            </GoogleMap>

            <div className={'flex flex-col md:flex-row md:grid-cols-4 my-3 mx-auto items-center md:w-9/12 justify-center gap-2'}>
                {markers.map((item) =>
                    <div key={item.id} className={'box-main w-1/4 border items-center justify-center rounded'}>
                            <div className={'flex flex-col items-center justify-center'}>
                                <h2 className={'font-medium'}>{item.id}</h2>
                                <p>
                                    {item.address.street}<br/>
                                    {item.address.city} {item.address.CP}<br/>
                                    {item.address.phone}<br/>
                                </p>
                            </div>
                            <div className={'flex items-center justify-center'}>
                                <button
                                    className={'button_secondary'}
                                    onClick={() => {
                                        setCenter({lat: item.lat, lng: item.lng})
                                        setZoom(30)
                                        window.location.hash = '';
                                        window.location.hash = '#map';
                                    }}>Voir
                                </button>
                            </div>
                    </div>)}

            </div>
        </>
    )

}

export default React.memo(MyMap)