import React from 'react';
import MyMap from "./Centers/Map";

export default class Centers extends React.Component {


    render() {
        return (
            <>
                <MyMap/>
            </>
        )
    }
}
