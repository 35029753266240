import React from 'react'
import {Link} from 'react-router-dom'
import {navList} from './Menu'

function lien(name, route) {
    return (
        <Link key={route} to={route} className='p-4'>
            {name}
        </Link>
    )
}

const DropDown = (state) => {
    const menu = navList.map((item) => lien(item.name, item.route))
    return (
        <div className={state.isOpen.isOpen ? 'flex flex-col text-center' : 'hidden'}>
            {menu}
        </div>
    )
}

export default DropDown;