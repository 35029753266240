import React from 'react';
import Banner from './Home/Banner';
import DescriptionList from "./Home/DescriptionList";
import FirstMessage from "./Home/FirstMessage";
import Footer from "../components/Footer";



export default class Home extends React.Component {
    render() {
        return (
            <>
                <Banner/>
                <DescriptionList/>
                <FirstMessage/>
                <Footer/>
            </>
        )
    }
}