import React from 'react';


const listCentres = [
    '',
    'Thionville',
    'Metz',
    'Saint-Avold'
]

export default class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {nom: '', email: '', centre: '', sujet: '', message: ''};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    resetForm() {
        this.setState({nom: '', email: '', centre: '', sujet: '', message: ''});
        this.myFormRef.reset()
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.state.centre)
            this.error('Veuillez choisir un centre à contacter')
        else
            fetch('http://localhost:3002/send', {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
            ).then((response) => {
                if (response.status === 'success') {
                    this.success('Message envoyé avec succès !');
                    this.resetForm()
                } else if (response.status === 'fail') {
                    this.error('Erreur lors de l\'envoi du message.')
                }
            })
    }

    error(e) {
        document.getElementById('success').innerHTML = ''
        document.getElementById('error').innerHTML = e
    }

    success(e) {
        document.getElementById('error').innerHTML = ''
        document.getElementById('success').innerHTML = e
    }

    render() {
        return (<>
                <form ref={(el) => this.myFormRef = el} className='gap-3 mx-auto max-w-2xl flex flex-col'
                      onSubmit={this.handleSubmit}>
                    <div className='flex flex-col'>
                        <label htmlFor='nom'>Nom</label>
                        <input className='border p-1' type="text" name='nom' value={this.state.nom.value}
                               onChange={this.handleChange} required/>
                    </div>

                    <div className='flex flex-col'>
                        <label htmlFor='email'>Email</label>
                        <input className='border p-1' type="email" name='email' value={this.state.email.value}
                               onChange={this.handleChange} required/>
                    </div>


                    <div className='flex flex-col'>
                        <label htmlFor='centre'>Centre à contacter</label>
                        <select name='centre' className='border p-1' value={this.state.centre.value}
                                onChange={this.handleChange}>
                            {Object.entries(listCentres).map(([key, value]) => <option value={value}
                                                                                       key={key}>{value}</option>)}
                        </select>
                    </div>

                    <div className='flex flex-col'>
                        <label htmlFor='sujet'>Sujet</label>
                        <input className='border p-1' type="text" name='sujet' value={this.state.sujet.value}
                               onChange={this.handleChange} required/>
                    </div>

                    <div className='flex flex-col'>
                        <label htmlFor='message'>Message</label>
                        <textarea className='border p-1' name='message' value={this.state.message.value}
                                  onChange={this.handleChange} required/>
                    </div>

                    <input className='cursor-pointer border p-1' type="submit" value="Envoyer"/>
                </form>
                <div id={'error'} className={'rounded mt-5 text-center text-red-600'}>
                </div>
                <div id={'success'} className={'rounded text-center text-green-600'}>
                </div>
            </>
        );
    }
}