import React from 'react';
import ContactForm from "./Contact/ContactForm";

export default class Contact extends React.Component {
    render() {
        return (
            <>
                <div className={'flex justify-center flex-col items-center'}>
                    <div>
                        <h3 className={'text-xl font-bold'}>Nous contacter</h3>
                    </div>
                    <div>
                        <span>Besoin d'un devis ? Plus d'informations ? C'est ici ! </span>
                    </div>
                </div>
                <ContactForm/>
            </>
        )
    }
}
