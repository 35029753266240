import React from 'react'

export default class Banner extends React.Component {
    render() {
        return (
            <div className="w-full bg-cover bg-center h-64" style={{backgroundImage: `url(/static/images/banner.jpg)`}}>
                <div className="flex items-center h-full w-full bg-gray-900 bg-opacity-50">
                    <div className="pl-32">
                        <p className="text-white text-xl font-semibold uppercase md:text-xl mb-2">Réparer votre
                            smartphone</p>
                        <button className="banner_btn">
                            <span className='h2' >Nous contacter</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}