import {Link} from 'react-router-dom'
import React from "react";
import DropDown from "./Dropdown";

export const navList = [{name: 'Accueil', route: '/'}, {name: 'Nos centres', route: '/centres'}, {name: 'Contact',route: '/contact'}]

class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {isOpen: false}
        this.toggle = () => {
            this.setState({
                isOpen: !this.state.isOpen
            })
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateHideMenu);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateHideMenu);
    }

    render() {
        const menu = navList.map((item) => this.lien(item.name, item.route))
        return <div>
            <nav className='flex justify-content h-16 items-center bg-white text-black relative shadow-sm'
                 role='navigation'>

                <div className='px-4 cursor-pointer md:hidden' onClick={this.toggle}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
                    </svg>
                </div>

                <Link to="/" className='p-4'>
                    <img alt={'Logo Digicom'} src={'/static/images/logo.jpg'} className={'h-10'} />
                </Link>

                <div className='pr-8 md:block hidden'>
                    <ul className={'menu-nav'}>
                        {menu}
                    </ul>
                </div>
            </nav>
            <div onClick={() => this.updateHideMenu()}>
                <DropDown isOpen={this.state}/>
            </div>
        </div>
    }

    updateHideMenu = () => {
        if (window.innerWidth < 768 && this.state.isOpen)
            this.toggle()
    }

    lien(name, route) {
        return (
            <li className={'menu-item'}>
                <Link key={route} to={route} className='menu-link'>
                    {name}
                </Link>
            </li>
        )
    }
}

export default Menu;