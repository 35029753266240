import React from "react";

import SimpleCard from "./SimpleCard";

class DescriptionList extends React.Component {
    render() {
        return (
            <div className={'flex flex-col md:flex-row items-center justify-center gap-5 mt-10 mx-auto'}>
                <SimpleCard icon={'/static/images/repair.jpg'} title={'Réparations'}
                      description={'Nous effectuons des réparations sur tous types de smartphones.'}/>
                <SimpleCard icon={'/static/images/smartphones.jpg'} title={'Ventes'}
                      description={'Apple, Samsung, Huawei, et bien d\'autres produits sont disponibles à prix avantageux !'}/>
                <SimpleCard icon={'/static/images/accessories.jpg'} title={'Accessoires'}
                      description={'Etuis de protection, verres trempés, écouteurs, et biens d\'autres sont disponibles à la vente'}/>
            </div>
        )
    }
}

export default DescriptionList;