export const myMarkers = [
    {
        id: 'Thionville',
        lat: 49.35881792023778,
        lng: 6.169020588954875,
        address: {
            street: '30 rue du luxembourg',
            city: 'Thionville',
            CP: '57100',
            phone: '03 82 82 11 06'
        },
        label: 'Digicom Gsm Services'
    },
    {
        id: 'Saint-Avold',
        lat: 49.10508461712608,
        lng: 6.710854702971805,
        address: {
            street: '47 rue Poincaré',
            city: 'Saint-Avold',
            CP: '57500',
            phone: '03 87 89 35 64'
        },
        label: 'Digicom Gsm Services'
    },
    {
        id: 'Metz',
        lat: 49.11044214685406,
        lng: 6.175244142939444,
        address: {
            street: '16 Rue Gambetta',
            city: 'Metz',
            CP: '57000',
            phone: '03 87 66 40 40'
        },
        label: 'Doc Gsm'
    },
    {
        id: 'Sarreguemines',
        lat: 49.111203807061806,
        lng: 7.067052880374094,
        address: {
            street: '22 Rue nationale',
            city: 'Sarreguemines',
            CP: '57200',
            phone: '09 82 39 33 72'
        },
        label: 'Digicom Gsm Services'
    }
]


export const config = {
    'Thionville': {
        address: {},
        coordinates:
            {
                lat: 49.358825923827744,
                lng: 6.169032295179443
            }
    },
    'Saint-Avold': {
        address: {},
        coordinates:
            {
                lat: 49.10508461712608,
                lng: 6.710854702971805
            }
    },
    'Metz': {
        address: {},
        coordinates:
            {
                lat: 49.110457486697506,
                lng: 6.175243954242437
            }
    },
    'Sarreguemines': {

        address: {},
        coordinates:
            {
                lat: 49.11121137749823,
                lng: 7.067031962499093
            }
    }
}