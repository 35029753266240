import React from "react";
import {Switch, Route} from 'react-router-dom';

import Menu from "../components/Menu";
import Home from '../pages/Home';
import Contact from '../pages/Contact';
import Centers from "./Centers";


const Main = () => {
    return (
        <Switch>
            <Route exact path='/' component={Home}/>
            <Route exact path='/contact' component={Contact}/>
            <Route exact path='/centres' component={Centers}/>
        </Switch>
    );
}

function App() {
    return (<>
        <Menu/>
        <Main/>
    </>)
}


export default App;